import { observable, action, decorate, computed } from "mobx"
import crypto from "crypto"
import { LOCATION_ID } from "../lib/helpers/constants"

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
})

export const IMAGE_GALLERY = [
  {
    originalAlt: " black cnc hand hole lock with cap",
    original: "high-exposure-full.jpg",
    thumbnail: "high-exposure-full.jpg",
  },
  {
    originalAlt: " black cnc hand hole lock with cap",
    original: "high-exposure-side-2.jpg",
    thumbnail: "high-exposure-side-2.jpg",
  },
  {
    originalAlt: " black cnc hand hole lock with cap",
    original: "front-side-no-lock.jpg",
    thumbnail: "front-side-no-lock.jpg",
  },
  {
    originalAlt: " black cnc hand hole lock",
    original: "bottom-front.jpg",
    thumbnail: "bottom-front.jpg",
  },
  {
    originalAlt: " black cnc hand hole lock",
    original: "complete-overhead.jpg",
    thumbnail: "complete-overhead.jpg",
  },
  {
    originalAlt: " black cnc hand hole lock with cap",
    original: "front-cap.jpg",
    thumbnail: "front-cap.jpg",
  },
  {
    originalAlt: " black cnc hand hole lock with cap",
    original: "front-low-lock.jpg",
    thumbnail: "front-low-lock.jpg",
  },
  {
    originalAlt: " black cnc hand hole lock with cap",
    original: "hardware.jpg",
    thumbnail: "hardware.jpg",
  },
]

export const PRODUCT = {
  name: "Light Pole Defender",
  subTitle: "(Locking Hand Hole Cover)",
  imgUrl: "front-side.jpg",
  id: 123,
  description:
    "The Light Pole Defender checks off all the boxes when it comes to protecting your light poles from wire theft. Light Pole Defender is a locking hand hole cover that prevents anyone from tampering with your lightpoles copper.",
  price: 24995,
  displayPrice: formatter.format(249.95),
  weight: 3.5,
}

export const TEST_PRODUCT = {
  name: "Test Product",
  subTitle: "(Not a real product for sale)",
  imgUrl: "front-side.jpg",
  id: 321,
  description: "This is not a product meant to be sold. This is a test proudct",
  price: 100,
  displayPrice: formatter.format(1),
}

function getTotal(price, qty) {
  return parseInt(qty) * parseInt(price)
}

function getFormattedTotal(price, qty) {
  return formatter.format(getTotal(price, qty) / 100)
}

function initialFieldValue({ isRequired = true }) {
  return {
    isDirty: false,
    isValid: false,
    isRequired: !!isRequired,
    value: null,
  }
}

const locationId = LOCATION_ID

const sectionOneNames = [
  "firstName",
  "lastName",
  "address",
  "state",
  "city",
  "zip",
  "phone",
  "email",
]

class CartStore {
  cart = []
  promoDiscountAmount = 0
  isValidPromoCode = false

  billingInfo = {
    firstName: initialFieldValue({ isRequired: true }),
    lastName: initialFieldValue({ isRequired: true }),
    companyName: initialFieldValue({ isRequired: false }),
    address: initialFieldValue({ isRequired: true }),
    suite: initialFieldValue({ isRequired: false }),
    state: initialFieldValue({ isRequired: true }),
    city: initialFieldValue({ isRequired: true }),
    zip: initialFieldValue({ isRequired: true }),
  }

  shippingInfo = {
    firstName: initialFieldValue({ isRequired: true }),
    lastName: initialFieldValue({ isRequired: true }),
    companyName: initialFieldValue({ isRequired: false }),
    address: initialFieldValue({ isRequired: true }),
    suite: initialFieldValue({ isRequired: false }),
    state: initialFieldValue({ isRequired: true }),
    city: initialFieldValue({ isRequired: true }),
    zip: initialFieldValue({ isRequired: true }),
  }

  contactInfo = {
    email: initialFieldValue({ isRequired: true }),
    phone: initialFieldValue({ isRequired: true }),
  }

  shippingOption = {
    shippingSelection: initialFieldValue({ isRequired: true }),
  }

  sameAsShipping = true
  paymentNonce = null
  orderId = null
  cardData = null
  orderConfirmationData = { line_items: [], tenders: [] }

  // this should come back from an API
  promoCode = {
    HORIZON_2021: 0.30256,
    ECLIPSE_2021: 0.30256,
    CONTRACTOR_2021: 0.30256,
    CONTRACTOR_2025: 0.30256,
  }

  // This is storing the actual promo code name used
  discountCode = null

  addToLocalStorage(key, data) {
    localStorage.setItem(key, JSON.stringify(data))
  }

  getFromLocalStorage(key) {
    return JSON.parse(localStorage.getItem(key))
  }

  addToSessionStorage(key, data) {
    sessionStorage.setItem(key, JSON.stringify(data))
  }

  getFromSessionStorage(key) {
    return JSON.parse(sessionStorage.getItem(key))
  }

  setDiscountCode(promoCode) {
    this.discountCode = promoCode
  }

  addPromoCode(discountCode) {
    if (this.promoCode[discountCode]) {
      this.setPromoDiscountAmount(this.promoCode[discountCode])
      this.setDiscountCode(discountCode)
      this.setisValidPromo(true)
    } else {
      this.setisValidPromo(false)
    }
  }

  setPromoDiscountAmount(discountValue) {
    this.promoDiscountAmount = discountValue
  }

  setisValidPromo(discountValue) {
    this.isValidPromoCode = discountValue
  }

  setOrderConfirmationData(order) {
    this.orderConfirmationData = order
  }

  setCardData(cardData) {
    this.cardData = cardData
  }

  setOrderId(orderId) {
    this.orderId = orderId
  }

  setShippingOption(value) {
    if (value) {
      this.shippingOption = {
        shippingSelection: { isDirty: true, isValid: true, value },
      }
    } else {
      this.shippingOption = {
        shippingSelection: { isDirty: true, isValid: false, value },
      }
    }
  }

  setPaymentNonce(nonce) {
    this.paymentNonce = nonce
  }

  get shippingLineItem() {
    const { value = {} } = this.shippingOption?.shippingSelection
    return {
      sku: "shipping-1",
      quantity: "1",
      name: value?.courier_service_code || "Shipping",
      variation_name: value?.name || "Shipping",
      base_price_money: {
        amount: Math.round((value?.amount || 0) * 100),
        currency: "USD",
      },
    }
  }

  get cartWeight() {
    return PRODUCT.weight * this.cartCount
  }

  get creditCardInfo() {
    const { tenders: cardInfoArray } = this.orderConfirmationData
    if (cardInfoArray.length > 0) {
      return cardInfoArray[0]?.card_details?.card
    }

    return {
      card_details: {
        card_brand: "",
        last_four: "",
      },
    }
  }

  get lineItems() {
    return this.cart.map(item => {
      return {
        sku: "1",
        quantity: item.qty.toString(),
        name: item.name,
        variation_name: item.color,
        base_price_money: {
          amount: item.price,
          currency: "USD",
        },
      }
    })
  }

  get taxes() {
    const { state } = this.shippingInfo
    const { amount } = this.shippingLineItem.base_price_money
    const total = parseInt(Math.round(this.calcTotal + amount))
    const discount = parseInt(Math.round(total * this.promoDiscountAmount))
    // this is calculating taxes after discount is applied to order
    if (state.value === "CA") {
      return parseInt(Math.round((total - discount) * 0.0725))
    }
    return 0
  }

  get paymentInfo() {
    const idempotency_key = crypto.randomBytes(22).toString("hex")

    return {
      source_id: this.paymentNonce,
      order_id: this.orderId,
      amount_money: {
        amount: this.subTotal,
        currency: "USD",
      },
      idempotency_key,
    }
  }

  get order() {
    const {
      firstName,
      lastName,
      companyName,
      address,
      suite,
      state,
      city,
      zip,
    } = this.shippingInfo
    const { email, phone } = this.contactInfo
    const {
      shippingSelection: { value },
    } = this.shippingOption
    const display_name = `${firstName.value} ${lastName.value}`
    const idempotency_key = crypto.randomBytes(22).toString("hex")
    const shippingNote = `${value?.courier_service_code}-${value?.amount}`

    return {
      idempotency_key,
      location_id: locationId,
      order: {
        location_id: locationId,
        taxes: [
          this.taxes
            ? {
                name: "CA taxes",
                category: "state taxes",
                percentage: "7.25",
                applied_taxes: {
                  amount: this.taxes,
                  currency: "USD",
                },
              }
            : null,
        ],
        line_items: [...this.lineItems, this.shippingLineItem],
        discounts: [
          {
            name: "Sale - $1.00 off",
            amount_money: {
              amount: this.calcDiscount,
              currency: "USD",
            },
            scope: "ORDER",
          },
        ],

        fulfillments: [
          {
            type: "SHIPMENT",
            shipment_details: {
              shipping_note: shippingNote,
              shipping_type: value?.shipping_type,
              carrier: "USPS",
              recipient: {
                display_name: companyName.value
                  ? companyName.value
                  : display_name,
                address: {
                  address_line_1: address.value,
                  address_line_2: suite.value,
                  first_name: firstName.value,
                  last_name: lastName.value,
                  email_address: email.value,
                  phone_number: phone.value,
                  postal_code: zip.value,
                  city: city.value,
                  state: state.value,
                },
              },
            },
          },
        ],
      },
    }
  }

  hasErrors(fieldName) {
    return this.formData[fieldName].isDirty && !this.formData[fieldName].isValid
  }

  get formData() {
    return {
      ...this.shippingInfo,
      shipping_address: {
        ...initialFieldValue({ isRequired: true }),
        value: { ...this.shippingInfo },
      },
      billing_address: {
        ...initialFieldValue({ isRequired: true }),
        value: this.sameAsShipping
          ? { ...this.shippingInfo }
          : { ...this.billingInfo },
      },
      ...this.contactInfo,
      ...this.shippingOption,
    }
  }

  normalizeInput(value, previousValue) {
    if (!value) return value
    const currentValue = value.replace(/[^\d]/g, "")
    const cvLength = currentValue.length

    if (!previousValue || value.length > previousValue.length) {
      if (cvLength < 4) return currentValue
      if (cvLength < 7)
        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
        3,
        6
      )}-${currentValue.slice(6, 10)}`
    } else if (value === previousValue) {
      return previousValue
    }
  }

  phoneInputHasErrors(value) {
    let error = false

    if (!value) error = true
    else if (value && value.length !== 14) error = true

    return error
  }

  get isSectionOneValid() {
    let valid = true
    sectionOneNames.forEach(key => {
      if (!this.formData[key].isValid) {
        valid = false
      }
    })
    return valid
  }

  setDirtyForm() {
    Object.keys(this.formData).forEach(key => {
      if (!this.formData[key].isValid && this.formData[key].isRequired) {
        this.formData[key].isDirty = true
      }
    })
  }

  setSameAsShipping(bool) {
    this.sameAsShipping = bool
  }

  validateEmail = email => {
    const pattern = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    if (email.match(pattern)) {
      return false
    } else return true
  }

  validate = {
    phone: value => this.phoneInputHasErrors(value),
    email: value => this.validateEmail(value),
  }

  setContactInfo(fieldName, value) {
    let newFormData

    if (fieldName && this.validate[fieldName](value)) {
      newFormData = {
        ...this.contactInfo,
        [fieldName]: { isDirty: true, isValid: false, value },
      }
    } else {
      newFormData = {
        ...this.contactInfo,
        [fieldName]: { isDirty: true, isValid: true, value },
      }
    }
    this.contactInfo = newFormData
  }

  setShippingInfo(fieldName, value) {
    let newFormData
    if (value) {
      newFormData = {
        ...this.shippingInfo,
        [fieldName]: { isDirty: true, isValid: true, value },
      }
    } else {
      newFormData = {
        ...this.shippingInfo,
        [fieldName]: { isDirty: true, isValid: false, value },
      }
    }
    this.shippingInfo = newFormData
    this.addToSessionStorage("shippingInfo", newFormData)
  }

  getShippingFromSessionStorage() {
    const cachedShippingInfo = this.getFromSessionStorage("shippingInfo")
    if (cachedShippingInfo) {
      this.shippingInfo = cachedShippingInfo
    }
  }

  setBillingInfo(fieldName, value) {
    let newFormData
    if (value) {
      newFormData = {
        ...this.billingInfo,
        [fieldName]: { isDirty: true, isValid: true, value },
      }
    } else {
      newFormData = {
        ...this.billingInfo,
        [fieldName]: { isDirty: true, isValid: false, value },
      }
    }
    this.billingInfo = newFormData
  }

  removeItem(item) {
    const updatedCart = this.cart.filter(
      cartItem => cartItem.color !== item.color
    )
    this.cart = updatedCart
    this.addToLocalStorage("cart", this.cart)
  }

  addItem(item, { qty, color }) {
    // We look at the item and if the color already exists
    // in the cart we update the qty of that color
    const decoratedItem = {
      ...item,
      qty,
      color,
      total: getTotal(item.price, qty),
      displayTotal: getFormattedTotal(item.price, qty),
    }
    const updatedCart = this.cart.map(cartItem => {
      if (cartItem.color === color) {
        const newQty = parseInt(cartItem.qty) + parseInt(qty)
        return {
          ...decoratedItem,
          qty: newQty,
          total: getTotal(decoratedItem.price, newQty),
          displayTotal: getFormattedTotal(decoratedItem.price, newQty),
        }
      }
      return cartItem
    })

    // checking if we should add a new item to cart because we may have just
    // updated the items qty in the cart already
    if (updatedCart.length === 0) {
      updatedCart.push(decoratedItem)
    }

    if (updatedCart.length > 0) {
      const itemColorIsInCart = updatedCart.some(item => item.color === color)
      if (!itemColorIsInCart) {
        updatedCart.push(decoratedItem)
      }
    }

    this.cart = updatedCart
    this.addToLocalStorage("cart", this.cart)
  }

  updateItemQty(item, { qty, color }) {
    const updatedCart = this.cart.map(cartItem => {
      if (cartItem.color === color) {
        const newQty = parseInt(qty)
        return {
          ...item,
          qty: newQty,
          total: getTotal(item.price, newQty),
          displayTotal: getFormattedTotal(item.price, newQty),
        }
      }
      return cartItem
    })
    this.cart = updatedCart
    this.addToLocalStorage("cart", this.cart)
  }

  get calcTotal() {
    const total = this.cart.reduce((acc, item) => {
      let total = acc
      total = total + item.total
      return total
    }, 0)

    return total
  }

  get calcDiscount() {
    // check if validPromo was entered
    const totalCartAmount =
      this.shippingLineItem.base_price_money.amount + this.calcTotal
    return parseInt(Math.round(totalCartAmount * this.promoDiscountAmount))
  }

  get discountDisplay() {
    return formatter.format(this.calcDiscount / 100)
  }

  get cartTotal() {
    return formatter.format(this.calcTotal / 100)
  }

  get subTotal() {
    return (
      this.shippingLineItem.base_price_money.amount +
      this.calcTotal +
      this.taxes -
      this.calcDiscount
    )
  }

  get cartCount() {
    return this.cart.reduce((acc, item) => {
      let qtyTotal = acc
      qtyTotal = qtyTotal + item.qty
      return qtyTotal
    }, 0)
  }

  setCart(cart) {
    this.cart = cart
  }

  clearCart() {
    this.cart.clear()
    localStorage.removeItem("cart")
  }

  dehydrate() {
    return {
      cart: this.cart,
      formData: this.formData,
      setFormData: this.setFormData,
      discountCode: this.discountCode,
      getFromLocalStorage: this.getFromLocalStorage,
      addToLocalStorage: this.addToLocalStorage,
      addToSessionStorage: this.addToSessionStorage,
      getShippingFromSessionStorage: this.getShippingFromSessionStorage,
    }
  }
}

decorate(CartStore, {
  cart: observable,
  cardData: observable,
  isValidPromoCode: observable,
  promoDiscountAmount: observable,
  discountCode: observable,
  paymentNonce: observable,
  orderId: observable,
  shippingInfo: observable,
  billingInfo: observable,
  contactInfo: observable,
  sameAsShipping: observable,
  shippingOption: observable,
  orderConfirmationData: observable,
  setSameAsShipping: action,
  setPromoDiscountAmount: action,
  setDiscountCode: action,
  getShippingFromSessionStorage: action,
  addPromoCode: action,
  addToLocalStorage: action,
  addToSessionStorage: action,
  setOrderConfirmationData: action,
  addItem: action,
  setContactInfo: action,
  setBillingInfo: action,
  setCart: action,
  setCardData: action,
  setShippingInfo: action,
  setPaymentNonce: action,
  setOrderId: action,
  removeItem: action,
  clearCart: action,
  setDirtyForm: action,
  setFormData: action,
  shippingLineItem: computed,
  formData: computed,
  order: computed,
  lineItems: computed,
  paymentInfo: computed,
  calcTotal: computed,
  creditCardInfo: computed,
  subTotal: computed,
  taxes: computed,
  cartCount: computed,
  isSectionOneValid: computed,
  discountDisplay: computed,
  calcDiscount: computed,
})

export default CartStore
